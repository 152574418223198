<template>
   <RadioPlayer v-if="typePlayer === 'file' && isPlayerActive && radioInfo && radioInfo.link_radio"
      :file="radioInfo.link_radio" :radio="radioInfo" :auto-play="true" downloadable />
   <RadioEfir v-if="typePlayer === 'efir' && isPlayerActive && radioProgram.status === 1 && isLive" :radio="radioInfo"
      :autoPlay="true" :inEfir="true" :cur_time="curr_time" :options="options" @clear="radioInfo = $event" />
</template>

<script setup>
import { ref, defineProps, watch, onBeforeMount, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import moment from 'moment';
import axios from 'axios'
import RadioPlayer from "@/components/Radio/RadioPlayer.vue"
import RadioEfir from "@/components/Radio/RadioEfir.vue"

const props = defineProps({
   typePlayer: {
      type: String,
      default: ''
   },
   isPlayerActive: {
      type: Boolean,
      default: false,
   }
});

const store = useStore()

const radiosListing = ref([])
const radioProgram = ref([])
const playList = ref([])

const radioId = ref(0)
const nickname = ref('')
const nicknameObj = ref('')

const inEfir = ref(false)
const showRadio = ref(false)
const indexMax = ref(0) // Кол-во чанков в плейлисте
const indexlive = ref(0) // Текущий чанк

const startVideo = ref(0)
const radioInfo = ref(null);
const componentKey = ref(true)

const curr_time = ref(0)
const durationInEfir = ref(0)
const newRadioRedirect = ref(false)
const isLive = ref(false)
const inLine = ref(0)
const timelive = ref(0)
const options = {
   autoplay: false,
   liveui: true,
   preload: "metdata",
   sources: [
      {
         src:
            `${process.env.VUE_APP_SITE_URL}:8088/m3u/stream.m3u8`,
         type: 'application/x-mpegURL'
      }
   ],
   language: "ru",
   controlBar: {
      fullscreenToggle: false,
   },
   fluid: false,
}

// Список радио
const GetListing = async () => {
   await axios
      .get(`api/v1/get/listing/radios/`)
      .then(response => {
         radiosListing.value = response.data
      })
      .catch(error => {
         console.log(error)
      })
}
// Начало, конец, название, статус, id программы
const GetRadioprogramToday = async () => {
   await axios
      .get(`/api/v1/get/radioprogram/today/`)
      .then(response => {
         if (String(response.data) == 'none' || (response.data.status != 1 && response.data.status != 4)) clearInterval(inLine.value)
         else {
            radioProgram.value = response.data
            radioId.value = response.data.id
            indexlive.value = response.data.index
            GetPlayList(response.data.id)
            loadProgramm(response.data.id)
         }
      })
      .catch(error => {
         console.log(error)
      })
}
// плейлист радиопрограммы
const GetPlayList = async (value) => {
   await axios
      .get(`/api/v1/get/radioprogram/radios/withads/${value}/`)
      .then(response => {
         playList.value = response.data
         indexMax.value = response.data.length

         if (indexMax.value < indexlive.value || indexMax.value == indexlive.value) {
            showRadio.value = false
            localStorage.removeItem("radio")
            store.commit('removeRadio')
            store.commit('initializeStore')
         } else {
            RadioNow()
            showRadio.value = true
            GoLive(playList.value[indexlive.value].radio, CorTime(playList.value[indexlive.value].time_to_start))
            durationInEfir.value = parseInt(Number(playList.value[indexlive.value].radio.duration) + 15)
         }
      })
      .catch(error => {
         console.log(error)
      })
}
// Установка начала трансляции
const RadioNow = () => {
   if (indexMax.value > indexlive.value || indexMax.value != indexlive.value) {
      startVideo.value = moment(playList.value[indexlive.value].time_to_start, "HH:mm:ss").format("HH:mm:ss")
   }
}
const GetNextRadio = async () => {
   await axios
      .patch(`/api/v1/get/radioprogram/next/radio/${radioId.value}/${indexlive.value + 1}/`)
      .then(response => {
         localStorage.removeItem("radio")

         store.commit('removeRadio')
         store.commit('initializeStore')

         setTimeout(() => {
            if (indexMax.value < indexlive.value || indexMax.value == indexlive.value) {
               showRadio.value = false
            } else {
               GetRadioprogramToday()
            }
         }, 500)

      })
      .catch(error => {
         console.log(error)
      })
}
const PublishRadio = async () => {
   await axios
      .patch(`/api/v1/radio/update/status/${radioInfo.value.id}/${0}/`)
      .then(response => {

      })
      .catch(error => {
         console.log(error)
      })
}
const forceRerender = async () => {
   componentKey.value = false;

   await nextTick();

   componentKey.value = true;
}
const CorTime = (timeValue) => {
   moment.locale('ru');
   const time = moment(timeValue, "HH:mm:ss").format("HH:mm")
   return time
}
const TimeLive = async () => {
   await axios
      .get(`/api/v1/get/time-live/`)
      .then(response => {
         timelive.value = moment(response.data.Time, "HH:mm:ss").format("HH:mm:ss")

         curr_time.value = moment.duration(timelive.value).asSeconds() - moment.duration(startVideo.value).asSeconds()

         if (durationInEfir.value != 0 && radioProgram.value.status == 1) {
            if (curr_time.value >= durationInEfir.value) {
               GetNextRadio()
            }
         }
      })
      .catch(error => {
         console.log(error)
      })
}
const GoLive = (value, time) => {
   radioInfo.value = value
}
const loadProgramm = async (value) => {
   await axios.get(`/api/v1/check/radioprogram/show/${value}/`)
      .then(res => {
         isLive.value = res.data.show
      })
}

onBeforeMount(() => {
   GetListing()
   GetRadioprogramToday()
})

onMounted(() => {
   radioInfo.value = store.state.radio;

   TimeLive()

   inLine.value = setInterval(() => {
      TimeLive()
   }, 2000)
})

const indexLive = computed(() => {
   const time = moment.duration(this.timelive).asSeconds();

   const index = playlistDraft.value.findIndex((item) => time <= moment.duration(item.time_to_start).asSeconds()) - 1;

   const indexLast = playlistDraft.value.length - 1;

   if (index === -2) {
      return indexLast;
   }

   return Math.max(0, index);
})

const playlistDraft = computed(() => {
   return this.playList.filter((item) => item.radio.draft !== 2);
})

store.watch((state) => state.radio, (value) => {
   console.log('change radio', value);
   radioInfo.value = value;

   // if (store.state.currentPlayer === 'file') {
   //    radioInfo.value = value;
   // }
});
</script>

<style lang="scss" scoped></style>