<template>
   <div class="music-modal" style="text-align: center">
      <div class="music-modal__left">
         <div class="pos_abs-ring" v-if="loading">
            <div class="lds-ring">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
         <div class="music-modal__play" ref="playBtn" :class="{ 'loadMusic': loading }"
            @click.native="playing ? pause() : playAudio()" :disabled="!loaded">
            <span v-if="!playing || paused">
               <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                     fill="#fff"></path>
               </svg>
            </span>
            <span v-else>
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H6.8C7.9201 21 8.48016 21 8.90798 20.782C9.28431 20.5903 9.59027 20.2843 9.78201 19.908C10 19.4802 10 18.9201 10 17.8V6.2C10 5.0799 10 4.51984 9.78201 4.09202C9.59027 3.71569 9.28431 3.40973 8.90798 3.21799C8.48016 3 7.9201 3 6.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202Z"
                     fill="white" />
                  <path
                     d="M14.218 4.09202C14 4.51984 14 5.0799 14 6.2V17.8C14 18.9201 14 19.4802 14.218 19.908C14.4097 20.2843 14.7157 20.5903 15.092 20.782C15.5198 21 16.0799 21 17.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H17.2C16.0799 3 15.5198 3 15.092 3.21799C14.7157 3.40973 14.4097 3.71569 14.218 4.09202Z"
                     fill="white" />
               </svg>
            </span>
         </div>
         <div class="radio__track_row modal-track" @click="inlive()">
            <p class="radio__track_active">
               <i18n-t keypath="radioprogram.live.status.online.singular" scope="global">...</i18n-t>
            </p>
         </div>
         <!--         <div class="music-modal__sound" :class="{'widthActive': inEfir, 'loadMusic': loading}">
               <input v-if="!inEfir" :style="{'background-size': posPercentage + '%' + '100%'}" 
                v-model="percentage" type="range" @click.native="setPosition()" :disabled="!loaded">
           </div> -->
         <audio preload="metadata" ref="audioPlayer" id="audioPlayer" class="radioplayer"></audio>
         <div class="music-modal__tile">
            <div class="music-modal__prev" :class="{ 'loadMusic': loading }">
               <img v-if="radio?.get_preview == ''" src="@/assets/img/Cover_radio.jpg" alt="">
               <img v-else :src="radio?.get_preview">
            </div>
            <div class="music-modal__info" :class="{ 'loadMusic': loading }">
               <p class="music-modal__track">{{ radio?.name }}</p>
               <p class="music-modal__name" v-if="radio?.author">{{ radio?.author?.name }}</p>
            </div>
         </div>
      </div>
      <div class="music-modal__right">
         <div class="music-modal__volume" :disabled="!loaded" :class="{ 'loadMusic': loading }">
            <span @click.native="mute()" v-if="!isMuted">
               <span v-if="playerVolume >= 0.51">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M16.7148 3.56503C16.1326 3.39174 15.5201 3.72328 15.3468 4.30554C15.1735 4.8878 15.505 5.50029 16.0873 5.67358C18.8142 6.48516 20.8009 9.01214 20.8009 12.0003C20.8009 14.9884 18.8142 17.5153 16.0873 18.3269C15.505 18.5002 15.1735 19.1127 15.3468 19.695C15.5201 20.2772 16.1326 20.6088 16.7148 20.4355C20.349 19.3539 23.0009 15.9882 23.0009 12.0003C23.0009 8.0123 20.349 4.64661 16.7148 3.56503Z"
                        fill="#019040" />
                     <path
                        d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                        fill="#019040" />
                     <path
                        d="M17.1786 9.02237C16.749 8.5928 16.0525 8.5928 15.623 9.02237C15.1934 9.45194 15.1934 10.1484 15.623 10.578C16.1614 11.1165 16.4008 11.3838 16.4008 11.9999C16.4008 12.6161 16.1612 12.884 15.623 13.4223C15.1934 13.8518 15.1934 14.5483 15.623 14.9779C16.0525 15.4074 16.749 15.4074 17.1786 14.9779L17.2068 14.9497C17.7686 14.3883 18.6007 13.5568 18.6007 11.9999C18.6007 10.4433 17.769 9.61233 17.2074 9.05119L17.1786 9.02237Z"
                        fill="#019040" />
                  </svg>
               </span>
               <span v-else-if="playerVolume <= 0.50 && playerVolume != 0">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                        fill="#019040" />
                     <path
                        d="M17.1786 9.02237C16.749 8.5928 16.0525 8.5928 15.623 9.02237C15.1934 9.45194 15.1934 10.1484 15.623 10.578C16.1614 11.1165 16.4008 11.3838 16.4008 11.9999C16.4008 12.6161 16.1612 12.884 15.623 13.4223C15.1934 13.8518 15.1934 14.5483 15.623 14.9779C16.0525 15.4074 16.749 15.4074 17.1786 14.9779L17.2068 14.9497C17.7686 14.3883 18.6007 13.5568 18.6007 11.9999C18.6007 10.4433 17.769 9.61233 17.2074 9.05119L17.1786 9.02237Z"
                        fill="#019040" />
                  </svg>
               </span>
               <span v-if="playerVolume == 0">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                        fill="#019040" />
                  </svg>
               </span>
            </span>
            <span @click.native="mute()" v-else="isMuted">
               <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M13.0997 5.40514C13.0997 3.52196 10.8877 2.50928 9.46218 3.73986L4.99078 7.59989H3.19995C1.98495 7.59989 1 8.58484 1 9.79984V14.1997C1 15.4147 1.98495 16.3997 3.19995 16.3997H4.99076L9.46211 20.26C10.8876 21.4907 13.0997 20.478 13.0997 18.5948V5.40514Z"
                        fill="#019040" />
                  </svg>
               </span>
            </span>
            <div>
               <!-- <span :style="{'width': posWidth + '%'}"></span> -->
               <input :style="{ 'background-size': posWidth + '%' + '100%' }" type="range" v-model="playerVolume"
                  max="1" step="0.01" min="0" />
            </div>
         </div>
         <div class="music-modal__arrow" @click="GoToUp" :class="{ 'loadMusic': loading }">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="var(--nav-green)" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </div>
         <div class="music-modal__close" @click="close" :class="{ 'loadMusic': loading }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M18 6L6 18" stroke="#ABABAB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               <path d="M6 6L18 18" stroke="#ABABAB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </div>
         <div class="music-modal__sound__mobile" v-if="!inEfir">
            <input v-if="!inEfir" :style="{ 'background-size': posPercentage + '%' + '100%' }" v-model="percentage"
               type="range" @click.native="setPosition()" :disabled="!loaded">
         </div>
      </div>
   </div>
</template>
<style lang="css">
.radioplayer {
   width: 0px;
   height: 0px;
}

.modal-track {
   padding: 0 30px;
   cursor: pointer;
}

.music-modal__volume {
   cursor: pointer;
}

.vjs-modal-dialog-content {
   pointer-events: none;
}

/*.modal-track p {
    color: #fff !important;
}

.modal-track .radio__track_active::before {
    background: #fff !important;
}
*/
</style>
<script>
import videojs from 'video.js';

export default {
   name: 'RadioEfir',
   data() {
      return {
         firstPlay: true,
         isMuted: false,
         loaded: false,
         playing: false,
         paused: false,
         percentage: 0,
         currentTime: '00:00:00',
         audio: null,
         totalDuration: 0,
         playerVolume: 0.5,
         posWidth: 50,
         posPercentage: 0,
         loading: true,

         device: '',
      }
   },
   components: {

   },
   props: {
      inEfir: {
         type: Boolean,
         default: false
      },
      cur_time: {
         type: Number,
         default: 0
      },
      radio: {
         type: [Object, String],
         default: {},
      },
      file: {
         type: String,
         default: null
      },
      autoPlay: {
         type: Boolean,
         default: false
      },
      options: {
         type: Object,
         default: {}
      },
   },
   mounted() {
      this.determineDevice()
      let html5 = {
         vhs: {
            overrideNative: true
         },
         nativeVideoTracks: false,
         nativeAudioTracks: false,
      }
      this.options.html5 = html5
      this.options.autoplay = true
      // this.options.controls = true
      const streamPath = this.device != 'iPhone' && this.device != 'iPad' ? 'm3u' : 'apple';
      this.options.sources[0].src = `${process.env.VUE_APP_SITE_URL}:8088/${streamPath}/stream.m3u8`;
      setTimeout(() => {
         this.audio = videojs(this.$refs.audioPlayer, this.options, () => {
            this.loading = false
            this.audio.volume(this.playerVolume)
            if (!this.audio.paused() == false) this.playing = true
         });
      }, 1500)
   },
   beforeDestroy() {
      if (this.audio) {
         // this.audio.nuevo();
         this.audio.dispose()
      }
   },
   computed: {
      isDesktopDevice() {
         if (window.innerWidth > 500) {
            return true
         } else {
            return false
         }
      }
   },
   methods: {
      determineDevice() {
         let userDeviceArray = [
            { device: 'Android', platform: /Android/ },
            { device: 'iPhone', platform: /iPhone/ },
            { device: 'iPad', platform: /iPad/ },
            { device: 'Symbian', platform: /Symbian/ },
            { device: 'Windows Phone', platform: /Windows Phone/ },
            { device: 'Tablet OS', platform: /Tablet OS/ },
            { device: 'Linux', platform: /Linux/ },
            { device: 'Windows', platform: /Windows NT/ },
            { device: 'Macintosh', platform: /Macintosh/ }
         ];

         let platform = navigator.userAgent;

         function getPlatform() {
            for (let i in userDeviceArray) {
               if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
               }
            }
            return 'Неизвестная платформа!' + platform;
         }

         this.device = getPlatform()
      },
      setPosition() {
         (this.paused) ? this.audio.pause() : this.audio.play()
         this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage);
      },
      stop() {
         this.audio.pause()
         this.paused = true
         this.playing = false
         this.audio.currentTime = 0
      },
      inlive() {
         this.audio.liveTracker.seekToLiveEdge();
      },
      playAudio() {
         if (this.playing) return
         // this.audio.play().then(_ => this.playing = true)
         this.audio.play().then(_ => this.playing = true)
         this.paused = false
      },
      pause() {
         this.playing = false
         this.paused = !this.paused;
         (this.paused) ? this.audio.pause() : this.audio.play()
      },
      download() {
         this.audio.pause()
         window.open(this.file, 'download')
      },
      mute() {
         this.isMuted = !this.isMuted
         this.audio.muted(this.isMuted)
      },
      close() {
         localStorage.removeItem("radio")
         this.$store.commit('removeRadio')
         this.$store.commit('initializeStore')
         this.$store.commit('deactivatePlayer')

         if (this.inEfir) {
            this.$emit('clear', {})
         }
      },
      GoToUp() {
         document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      },
   },
   watch: {
      playerVolume(value) {
         this.posWidth = parseInt(value * 100)
         this.audio.volume(value)
      },
      percentage(value) {
         this.posPercentage = parseInt(value)
      },
   },
   created() {

   },
};
</script>