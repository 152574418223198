import axios from "axios";
import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { tryCatch } from "./utils/handle";
import { cloneDeep, has } from "lodash";

export const DEFAULT_LOCALE = process.env.VUE_APP_DEFAULT_LOCALE || 'ru-cyrl';
export const SUPPORT_LOCALES = (process.env.VUE_APP_SUPPORT_LOCALES || 'ru-cyrl').split(',');

export const LOCALE_NAMES = {
    'ru-cyrl': 'Cyrillic',
    'ru-latn': 'Latin',
};

export const LOCALE_FALLBACKS = {
    'ru-cyrl': 'ru-latn',
    'ru-latn': 'ru-cyrl',
}

function _plural(pluralMessages) {
    for (const key in pluralMessages || []) {
        if (!has(pluralMessages, key) || !pluralMessages[key]) {
            continue;
        }

        if (typeof pluralMessages[key] === 'object' && pluralMessages[key] !== null) {
            _plural(pluralMessages[key]);
        }
        if (pluralMessages[key].plural) {
            pluralMessages[key].plural = pluralMessages[key].plural.join('|');
        }
    }

    return pluralMessages;
}

function pipePlural(messages) {
    const pluralMessages = cloneDeep(messages);

    return _plural(pluralMessages);
}

const localeFallback = (locale = DEFAULT_LOCALE) => async (e) => {
    const locales = await import(`./assets/locales/${locale}.json`);

    return locales.default;
};

export async function loadLocales(lang = DEFAULT_LOCALE) {
    return await tryCatch(localeFallback(lang), async (locale = DEFAULT_LOCALE) => {
        const r = await axios.get(`/api/v1/translations/${locale}/`);

        if (r.status !== 200) {
            throw new Error('Cannot load locale');
        }

        const { data } = r;

        return data;
    }, lang);
}

export function setupI18n(options = { locale: DEFAULT_LOCALE }) {
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

function setHeader(locale) {
    axios.defaults.headers.common['Accept-Language'] = locale;
    document.querySelector('html').setAttribute('lang', locale);
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
    } else {
        i18n.global.locale.value = locale;
    }

    setHeader(locale);
}

export async function loadLocaleMessages(i18n, locale) {
    const _messages = await loadLocales(locale);

    const messages = pipePlural(_messages);

    i18n.global.setLocaleMessage(locale, messages);

    return nextTick();
}

export async function loadLocaleMessagesOnHook(locale, setLocaleMessage, newLocale) {
    const _messages = await loadLocales(newLocale);
    const messages = pipePlural(_messages);

    locale.value = newLocale;

    setLocaleMessage(newLocale, messages);

    setHeader(newLocale);

    return nextTick();
}