import { createStore } from "vuex";

export default createStore({
   state: {
      isAuthenticated: false,
      token: "",
      nickname: "",
      rtime: 0,
      isLoading: false,
      theme: false,

      radio: {},
      currentPlayer: "",
      isPlayerActive: false,
   },
   mutations: {
      initializeStore(state) {
         if (localStorage.getItem("token")) {
            state.token = localStorage.getItem("token");
            state.isAuthenticated = true;
         } else {
            state.token = "";
            state.isAuthenticated = false;
         }

         if (localStorage.getItem("theme")) {
            state.theme = localStorage.getItem("theme");
         } else {
            state.theme = false;
         }

         if (localStorage.getItem("radio")) {
            state.radio = JSON.parse(localStorage.getItem("radio"));
         } else {
            localStorage.setItem("radio", JSON.stringify(state.radio));
         }

         if (localStorage.getItem("nickname")) {
            state.nickname = localStorage.getItem("nickname");
         } else {
            state.nickname = "";
         }

         if (localStorage.getItem("rtime")) {
            state.rtime = localStorage.getItem("rtime");
         } else {
            state.rtime = 0;
         }
      },
      setIsLoading(state, status) {
         state.isLoading = status;
      },
      setRadio(state, radio) {
         state.radio = radio;
         localStorage.setItem("radio", JSON.stringify(state.radio));
      },
      setTheme(state, theme) {
         state.theme = theme;
      },
      setNickname(state, nickname) {
         state.nickname = nickname;
      },
      setRtime(state, rtime) {
         state.rtime = rtime;
      },
      removeNickname(state) {
         state.nickname = "";
      },
      removeRadio(state) {
         state.radio = {};
      },
      removeTheme(state) {
         state.theme = false;
      },
      setToken(state, token) {
         state.token = token;
         state.isAuthenticated = true;
      },
      removeToken(state) {
         state.token = "";
         state.isAuthenticated = false;
         state.anketaCheck = false;
      },
      activatePlayer(state, typePlayer) {
         state.isPlayerActive = true;
         state.currentPlayer = typePlayer;
      },
      deactivatePlayer(state) {
         state.isPlayerActive = false;
         state.currentPlayer = "";
      },
   },
   actions: {},
   modules: {},
});
