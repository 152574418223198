import axios from "axios";
import moment from "moment";
import { tryCatch } from "@/utils/handle";
// 0 не рабоатет трансляция
// 1 идет
// 2 подготавливается
// 3 готов к запуску
// 4 трансляция закончилась

export const getTeleprogramList =
   (refs, actions = {}) =>
   async () => {
      await tryCatch(null, async () => {
         const { tv, indexlive, interval, inLine, playList, endEfirStr } = refs;
         const { updateTeleId, fetchPlaylist } = actions;

         const response = await axios.get("/api/v1/get/teleprogram/today/");
         const { data } = response;

         if (String(data) !== "none") {
            endEfirStr.value = data?.finished_text;
         }

         if (String(data) === "none" || data.status !== 1) {
            ``;
            clearInterval(interval.value);
            clearInterval(inLine.value);
            return;
         }

         tv.value = data;
         indexlive.value = Math.min(
            data.index,
            Math.max((playList.value || []).length - 1, 0)
         );
         typeof updateTeleId === "function" && updateTeleId(data.id);
         typeof fetchPlaylist === "function" && (await fetchPlaylist(data.id));
      });
   };

export const getPlayList =
   (refs, actions = {}) =>
   async (value, update = false) => {
      await tryCatch(null, async () => {
         const { loaderNew, showOnlineBool, indexMax, playList } = refs;
         const { videoNow, goLive } = actions;

         const response = await axios.get(
            `/api/v1/get/teleprogram/videos/withads/${value}/`
         );
         const { data = {} } = response;
         const { results = [] } = data;

         loaderNew.value = false;
         showOnlineBool.value = data.show;

         setTimeout(() => {
            indexMax.value = results.length;
            playList.value = results;
            console.log("playList.value", playList.value);
            typeof videoNow === "function" && videoNow(update);
            loaderNew.value = false;
            typeof goLive === "function" && goLive();
         }, 500);
      });
   };

export const getVideoNow = (refs) => () => {
   const {
      indexMax,
      indexlive,
      videoId,
      videoInfo,
      playList,
      startVideo,
      startVideoDef,
      durationOnline,
   } = refs;

   if (indexMax.value < indexlive.value) return;
   if (
      !Array.isArray(playList.value) ||
      playList.value.length <= indexlive.value
   )
      return;

   console.log("indexlive.value", indexlive.value);
   videoId.value = playList.value[indexlive.value].id;
   videoInfo.value = playList.value[indexlive.value].video;
   startVideo.value = playList.value[indexlive.value].time_to_start;
   startVideoDef.value = playList.value[indexlive.value].time_to_start;
   durationOnline.value = parseInt(
      Number(playList.value[indexlive.value].video.duration) + 30
   );
};

export const getNextVideo = (refs, actions) => async () => {
   await tryCatch(null, async () => {
      const {
         teleId,
         indexlive,
         tv,
         curr_time,
         inLine,
         timeOnline,
         indexMax,
         endOver,
      } = refs;
      const { fetchPlaylist } = actions;
      const nextIndex = indexlive.value + 1;

      const response = await axios.put(
         `/api/v1/get/teleprogram/next/${teleId.value}/${nextIndex}/`
      );
      const { data } = response;
      console.log(data);
      tv.value = data;
      indexlive.value = data.index;
      curr_time.value = 0;
      clearInterval(inLine.value);
      inLine.value = 0;
      timeOnline.value = 0;

      if (indexMax.value < indexlive.value) {
         endOver.value = true;
         return;
      }

      typeof fetchPlaylist === "function" &&
         (await fetchPlaylist(data.id, true));
   });
};

export const getPublishVideo = (videoInfoRef) => async () => {
   await tryCatch(null, async () => {
      if (!videoInfoRef.value) return;
      if (typeof videoInfoRef.value !== "object") return;
      if (!videoInfoRef.value.id) return;

      await axios.patch(
         `/api/v1/video/update/status/${videoInfoRef.value.id}/0/`
      );
   });
};

export const getTimeLive =
   (refs, actions = {}) =>
   async () => {
      await tryCatch(null, async () => {
         const { timelive, curr_time, durationOnline, tv, startVideo } = refs;
         const { updatePercentage, publishVideo, fetchNextVideo } = actions;

         const response = await axios.get("/api/v1/get/time-live/");

         timelive.value = moment(response.data.Time, "HH:mm:ss").format(
            "HH:mm:ss"
         );
         curr_time.value =
            moment.duration(timelive.value).asSeconds() -
            moment.duration(startVideo.value).asSeconds();
         typeof updatePercentage === "function" &&
            updatePercentage(durationOnline.value);

         if (!tv.value) return;
         if (durationOnline.value !== 0 && tv.value.status === 1) {
            if (curr_time.value < durationOnline.value) return;
            typeof publishVideo === "function" && (await publishVideo());
            typeof fetchNextVideo === "function" && (await fetchNextVideo());
         }
      });
   };

export const getCheckTvStatus = (refs) => async () => {
   await tryCatch(null, async () => {
      const { tv, online, endOver } = refs;

      const response = await axios.get("/api/v1/get/status/teleprogram/run/");

      tv.value = response.data;
      if (response.data.status === 0 || response.data.status === 4) {
         console.log("status", response.data.status);
         endOver.value = true;
         online.value = false;
      }
   });
};

export const getUpdatePercentage = (refs) => (duration) => {
   const { timeOnline, curr_time } = refs;

   timeOnline.value = parseInt(
      (100 * Number(curr_time.value)) / (Number(duration) || 1)
   );
};

export const getGoLive = (onlineRef) => () => {
   onlineRef.value = true;
};
