<script setup>
import { provide, ref } from "vue";
import Header from "@/components/Header/Header";
import HomePlayer from "@/views/Player/HomePlayer";
import LivePoll from "@/components/LivePoll/LivePoll";
import SwiperCategory from "@/components/Swiper/MainCategory";
import SlideShow from "@/views/SlideShow/SlideShow";
import Donate from "@/components/Donate/Donate";
import AboutMain from "@/components/About/AboutMain";

const teleId = ref(0);

const updateTeleId = (id) => {
   teleId.value = id;
};

provide('teleId', teleId);
provide('updateTeleId', updateTeleId);

const online = ref(false)
</script>

<style lang="scss" scoped>
@import '@/assets/css/Home/main.css';
</style>

<template>
   <Header />
   <Suspense>
      <template #fallback></template>
      <HomePlayer v-show="online" v-model="online" />
   </Suspense>

   <Suspense>
      <template #fallback></template>
      <SlideShow />
   </Suspense>

   <Suspense>
      <template #fallback></template>
      <LivePoll />
   </Suspense>

   <SwiperCategory />
   <AboutMain />
   <Donate />
</template>