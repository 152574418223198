<template>
   <div class="player">
      <div class="container">
         <!-- тут было v-if=online -->
         <section class="defaultStage" v-if="online">
            <LiveVideo :key="componentKey" :teleId="teleId" :videoInfo="videoInfo" :options="videoOptions"
               :curr_time="curr_time" :videoId="videoId" :durationInEfir="durationOnline" :timeOfEfir="tv.time_to_start"
               :timelive="timelive" :videosList="playList" />
         </section>
         <section v-else class="defaultStage in_not_efir end-active_efir wait-efir_block">
            <div class="posIn-not_efir">
               <h3 v-if="endEfirStr.length">{{ endEfirStr }}</h3>
               <i18n-t v-else tag="h3" keypath="teleprogram.live.status.offline.singular" scope="global"></i18n-t>
            </div>
         </section>
         <LiveChat className="player" :id="teleId" idKey="teleprogram_id" chatType="teleprogram" v-if="online" />
         <LiveTrack :playList="playList" :currentTime="timelive" v-if="online" />
      </div>
   </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onBeforeUnmount, inject, ref, computed, defineModel } from "vue";
import { useStore } from "vuex";
import { decodeTime } from "@/utils/datetime";
import {
   getVideoNow, getPlayList, getTeleprogramList,
   getUpdatePercentage, getGoLive, getNextVideo,
   getPublishVideo, getTimeLive, getCheckTvStatus
} from "./player";
import LiveVideo from "@/components/Video/MainComponent";
import LiveTrack from "@/components/Swiper/LiveTrack";
import LiveChat from "@/components/Chat/LiveChat";

const videoOptions = {
   autoplay: true,
   controls: true,
   liveui: true,
   preload: 'metadata',
   sources: [
      {
         src: `${process.env.VUE_APP_SITE_URL}:8088/hls/stream.m3u8`,
         type: "application/x-mpegURL"
      }
   ],
   controlBar: {
      pictureInPictureToggle: false,
      ProgressControl: false,
   },
   fluid: false,
   language: "ru",
   videoPk: ''
};

const store = useStore();

const teleId = inject('teleId');
const updateTeleId = inject('updateTeleId');

const endOver = ref(false);
const componentKey = ref(false);
const loaderNew = ref(false);
const inLine = ref(0);
const interval = ref(0);
const playList = ref([]);
const indexMax = ref(0);
const indexlive = ref(0);
const timelive = ref("00:00:00");
const startVideo = ref("00:00:00");
const startVideoDef = ref("00:00:00");
const durationOnline = ref(0);
const showOnlineBool = ref(false);
const tv = ref({});
const videoId = ref(0);
const videoInfo = ref({});
const curr_time = ref(0);
const timeOnline = ref(0);
// const online = ref(false);
const online = defineModel(false);
const endEfirStr = ref("")



const goLive = getGoLive(online);
const videoNow = await getVideoNow({
   indexMax, indexlive, videoId,
   videoInfo, playList, startVideo,
   startVideoDef, durationOnline
});

const fetchPlaylist = getPlayList({
   loaderNew, showOnlineBool, indexMax, playList
}, { videoNow, goLive });

const fetchTeleprogramList = getTeleprogramList({
   tv, indexlive, interval, inLine, playList, endEfirStr
}, { updateTeleId, fetchPlaylist });

const updatePercentage = getUpdatePercentage({ curr_time, timeOnline });

const fetchNextVideo = getNextVideo({
   teleId, indexlive, tv, curr_time, inLine,
   timeOnline, indexMax, endOver,
}, { fetchPlaylist });

const publishVideo = getPublishVideo(videoInfo);

const timeLive = getTimeLive({
   timelive, curr_time, durationOnline, tv, startVideo
}, { updatePercentage, publishVideo, fetchNextVideo });

const checkTvStatus = getCheckTvStatus({ tv, online, endOver });

localStorage.removeItem('radio');
store.commit('removeRadio');
store.commit('initializeStore');

onBeforeMount(async () => {
   await fetchTeleprogramList();
});

onMounted(async () => {
   await timeLive();

   inLine.value = setInterval(async () => await timeLive(), 2000);
   interval.value = setInterval(async () => await checkTvStatus(), 5000);
});

onBeforeUnmount(() => {
   clearInterval(inLine.value);
   clearInterval(interval.value);
});

const hasTv = computed(() => Object.keys(tv.value || {}).length != 0);
const hasPlaylist = computed(() => playList.value.length > 0);
const hasTvStarted = computed(() => [1, 4].includes(tv.value.status || 0));

const playerActive = computed(() => {
   return hasTv.value && hasPlaylist.value && showOnlineBool.value && hasTvStarted.value;
});
</script>

<style lang="scss" scoped>
.defaultStage {
   &__body {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
   }
}
</style>
