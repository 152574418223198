<template>
   <Header />
   <section class="catalog-radio">
      <div class="container">
         <div class="tv-radio__radio_wrapper">
            <div class="tv-radio__radio">
               <p class="tv-radio__radio_link blue" @click="GoTo('/catalog')">
                  <i18n-t keypath="search.category.tv.singular" scope="global"></i18n-t>
               </p>
               <p class="tv-radio__radio_link green active">
                  <i18n-t keypath="search.category.radio.singular" scope="global"></i18n-t>
               </p>
            </div>
         </div>
         <div class="category__row_wrapper">
            <div class="category__row">
               <a :class="{ 'active': categoryCurrent == 0 }" @click="SelectAllRadio(0)">
                  <i18n-t keypath="search.category.all.singular" scope="global"></i18n-t>
               </a>
               <a v-for="list, index in categorys" @click="SelectCategory(list.id, index + 1, list.name)"
                  :class="{ 'active': categoryCurrent == index + 1 }">
                  {{ list.name }}
               </a>
            </div>
         </div>
      </div>
   </section>

   <section v-if="categoryCurrent == 0">
      <PopularList />
   </section>


   <section class="music-slider__padding" v-if="categoryCurrent == 0">
      <CatalogRadios :radiosArray="radiosListing" />
   </section>
   <section class="category">
      <div class="container">
         <div class="cat_flex">
            <h2>{{ categoryName }}</h2>
            <select v-model="selectCat">
               <option value="0">
                  <i18n-t keypath="search.category.sorting.all.singular" scope="global"></i18n-t>
               </option>
               <option value="1">
                  <i18n-t keypath="search.category.sorting.popular.singular" scope="global"></i18n-t>
               </option>
               <option value="2">
                  <i18n-t keypath="search.category.sorting.new.singular" scope="global"></i18n-t>
               </option>
            </select>
         </div>
         <div class="category__radio over__hide">
            <a @click="SetRadio(item)" v-for="item in radios" class="music-slider__item margin-zero">
               <div class="music-slider__prev">
                  <img :src="item.get_preview" v-if="item.get_preview">
                  <img src="@/assets/img/Cover_radio.jpg" v-else>
                  <div class="list-film__watch">
                     <p>{{ item.prosmotri }}</p>
                  </div>
                  <div class="list-film__date">
                     <p>{{ CorDate(item.date_added) }}</p>
                  </div>
               </div>
               <div class="music-slider__item_info">
                  <p class="music-slider__track">{{ item.name }}</p>
                  <p class="music-slider__name" v-if="item.author">{{ item.author.name }}</p>
               </div>
            </a>
         </div>
         <ul class="pagination pagination__green" v-if="count_pages > 0 && count_pages != 1">
            <li v-for="item in filteredPages">
               <a @click="setPage(item)" :class="{ 'active': item == page }">
                  <p>{{ item }}</p>
               </a>
            </li>
         </ul>
      </div>
   </section>
</template>

<style type="text/css">
.over__hide {
   overflow: hidden;
}

.margin-zero {
   margin: 0;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import { formatDate } from '@/utils/datetime';
import Header from '@/components/Header/Header'
import Donate from '@/components/Donate/Donate'
import TvCatalog from '@/components/Swiper/TvCatalog'

import CatalogRadios from '@/components/Swiper/Radios/CatalogRadios'

import PopularList from '@/components/Radio/RadioBlocks/PopularList'

export default {
   name: 'MainCatalog',
   data() {
      return {
         categorys: [],
         radios: [],

         radiosListing: [],

         categoryIdc: "",
         categoryCurrent: 0,
         selectCat: 0,
         categoryName: this.$t('catalog.radio.title.singular'),
         prosmotri: false,
         date_added: "",

         page: 1,
         pageOffset: 30,
         itemsLenght: 0,
         count_pages: 0,
      }
   },
   methods: {
      GoTo(redirect) {
         this.$router.push(redirect)
      },
      async GetListing() {
         await axios
            .get(`/api/v1/get/listing/radios/`)
            .then(response => {
               this.radiosListing = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetCategoryAll() {
         await axios
            .get(`/api/v1/get/radio/category/all/`)
            .then(response => {
               this.categorys = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetCategoryVideos() {
         var cat = this.categoryIdc
         if (this.categoryCurrent == 0) cat = ''

         await axios
            .get(`/api/v1/get/radio/category/?category_id=${cat}&prosmotri=${this.prosmotri}&date_added=${this.date_added}&page=${this.page}`)
            .then(response => {
               this.radios = response.data.results

               this.itemsLenght = response.data.count
               this.count_pages = Math.round(this.itemsLenght / this.pageOffset)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async HistoryAddRadio(idc) {
         await axios
            .patch(`/api/v1/add/radio/history/${idc}/`)
            .then(response => {
               console.log('history add')
            })
            .catch(error => {
               console.log(error)
            })
      },
      SetRadio(value) {
         // const radio = JSON.stringify(value)
         // console.log('sos', radio);

         // this.$store.commit('setRadio', radio)
         // localStorage.setItem("radio", radio)

         // if (value && value.id)
         //    this.HistoryAddRadio(value.id)
         // this.$store.commit('initializeStore')
         const radio = JSON.stringify(value);

         this.$store.commit('setRadio', radio);
         localStorage.setItem('radio', radio);
         if (value?.id) {
            this.HistoryAddRadio(value.id)
         }
         this.$store.commit('initializeStore');
         this.$store.commit('activatePlayer', 'file')
      },
      SelectAllRadio(num) {
         this.categoryCurrent = num
         this.categoryName = this.$t('catalog.radio.title.singular');

         if (this.selectCat == 0) {
            this.prosmotri = false
            this.date_added = ""
            this.GetCategoryVideos()
         }

         if (this.selectCat == 1) {
            this.prosmotri = true
            this.date_added = ""
            this.GetCategoryVideos()
         }

         if (this.selectCat == 2) {
            this.prosmotri = false
            this.date_added = true
            this.GetCategoryVideos()
         }
      },
      setPage(page) {
         if (page == '...') return
         this.page = page
         this.GetCategoryVideos()
      },
      SelectCategory(idc, num, name) {
         this.categoryIdc = idc
         this.categoryCurrent = num
         this.categoryName = name
         this.page = 1

         this.GetCategoryVideos()
      },
      CorDate(date) {
         return formatDate(date, 'DD MMMM YYYY', this.$i18n.locale);
      },

   },
   components: {
      Header,
      Donate,
      TvCatalog,
      CatalogRadios,
      PopularList
   },
   created() {
      this.GetListing()
      this.GetCategoryAll()
      this.SelectAllRadio(0)
   },
   computed: {
      filteredPages() {
         let array = []
         for (let i = 1; i <= this.count_pages; i++) {
            array.push(i);
         }
         if (array.length <= 6) {
            return array
         } else {
            if (this.page == 1) {
               return [this.page, this.page + 1, this.page + 2, this.page + 3, '...', array.length]
            } else if (array.length - 5 < this.page) {
               return [array.length - 5, array.length - 4, array.length - 3, array.length - 2, array.length - 1, array.length]
            } else {
               return [this.page - 1, this.page, this.page + 1, this.page + 2, '...', array.length]
            }
         }
      },
   },
   //   beforeUnmount() {
   //     this.SetRadio({});
   //   },
   watch: {
      selectCat(value) {
         if (this.categoryCurrent == 0) this.SelectAllRadio(0)

         if (this.selectCat == 0) {
            this.prosmotri = false
            this.date_added = ""
            this.GetCategoryVideos()
         }

         if (this.selectCat == 1) {
            this.prosmotri = true
            this.date_added = ""
            this.GetCategoryVideos()
         }

         if (this.selectCat == 2) {
            this.prosmotri = false
            this.date_added = true
            this.GetCategoryVideos()
         }
      },
   },
}
</script>
